.box {
  text-align: center;
  padding: 15px;
  background-color: #EEEEEE;
  border-radius: 5px;
  cursor: pointer;
}
.box:hover {
  background-color: #09AFA7;
  color: #FFFFFF;
}
.box-active {
  background-color: #09AFA7;
  color: #FFFFFF;
}
h1{
  margin: 0;
  padding: 0;
  font-size: 25px;
}
.app-logo{
  margin: 15px;
  cursor: pointer;
  transition: 0.3s;
}
.app-logo:hover{
  transform: translateX(150px);
  transition: 0.3s;
}
.icon{
  color: #09AFA7;
}
.ft{
  float: left;
}
.ft{
  float: right;
}
.icon-search{
  position: relative;
  margin-top: -25px;
  margin-right: 10px;
}